.ant-modal-mask{
  background-color: rgba(0,0,0,.5);
}
.ant-modal-body{
  padding: 0;
}

.ant-modal-content {
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
}

.ant-modal-close{
  width: 26px;
  height: 26px;
  //background: #fff;
  //border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  //box-shadow: 0 0 7px rgba(0,0,0,.2);
  // .ant-modal-close-x{
  //   width: 26px;
  //   height: 26px;
  //   display: inline-block;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   color: #0083e9;
  //   line-height: 26px;
  // }
}
  span.ant-modal-close-x{
    width: 26px;
    height: 26px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    color: #000000b3;
    line-height: 26px;
}
// .ant-modal-close{
//   width: 34px;
//   height: 34px;
//   border-radius: 50%;
//   right: -17px;
//   top: -17px;
//   background-color: rgb(87,87,87);
//   color: #fff;
//   &:hover{
//     background-color: rgb(90,162,235);
//     color: #fff;
//   }
// }
// .ant-modal-close-x{
//   width: 34px;
//   height: 34px;
//   line-height: 34px;
//   font-size: 18px;
// }

.back-store-select {
  width: 294px;
  font-size: 12px;
  .ant-select-selection {
    height: 36px;
    border-radius: 0;
  }
  .ant-select-arrow:before {
    font-family: "iconfont" !important;
    content: '\e750' !important;
    font-size: 12px;
  }
}
.back-store-option {

}

