
app-template,app-member{
  /*display: flex;
  flex-direction: column;*/
  display: block;
  //background-color: #f2f2f2;
  width: 100%;
  height: 100vh;
}
.app-body{
  /*flex: 1;*/
  margin-top: 80px;
  padding-bottom: 20px;
  //background-color: #f2f2f2;
  min-height: calc(100vh - 117px);
}

.frontend-new-central {
  width: 85%;
  //width: 1190px;
  //max-width: 1190px;
  margin: 0 auto;
}
.app-content{
  flex: 1;
  height: calc(100vh - 57px);
  // background-color: #e9ebeb;
  background-color: #f6f6f6;
}
app-header,app-menu, app-new-header, app-new-menu, app-template-center, app-classify{
  display: block;
  width: 100%;
}

app-coop{
  display: block;
  background-color: #f7f7fa;
}

app-shared{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
app-help-center{
  height: 100vh;
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 5px;
    background-color: rgba(0,0,0,.3);
  }
  &::-webkit-scrollbar-track{
    border-radius: 5px;
    background-color: #eee;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
}

