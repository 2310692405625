@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "~handsontable/dist/handsontable.full.min.css";
@import "./styles/font.css";
@import '../../frontend/src/assets/iconfont/iconfont.css';
article,
aside,
footer,
header,
nav,
main,
section,
summary,
details,
hgroup,
figure,
figcaption {
  display: block;
}

html {
  box-sizing: border-box;
  font: 12px/1 "Microsoft YaHei", Arial, "宋体", sans-serif;
}

body,
p,
ul,
dl,
dd {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  list-style: none;
  padding: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

img {
  vertical-align: middle;
}

a,
a:hover {
  text-decoration: none !important;
  color: inherit;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

.front-scroll-bar {
  overflow-x: hidden;
  overflow-y: auto;
}
.front-scroll-bar::-webkit-scrollbar {
  width: 5px;
}
.front-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
}
.front-scroll-bar::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #eee;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

app-template, app-member {
  /*display: flex;
  flex-direction: column;*/
  display: block;
  width: 100%;
  height: 100vh;
}

.app-body {
  /*flex: 1;*/
  margin-top: 80px;
  padding-bottom: 20px;
  min-height: calc(100vh - 117px);
}

.frontend-new-central {
  width: 85%;
  margin: 0 auto;
}

.app-content {
  flex: 1;
  height: calc(100vh - 57px);
  background-color: #f6f6f6;
}

app-header, app-menu, app-new-header, app-new-menu, app-template-center, app-classify {
  display: block;
  width: 100%;
}

app-coop {
  display: block;
  background-color: #f7f7fa;
}

app-shared {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

app-help-center {
  height: 100vh;
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}
app-help-center::-webkit-scrollbar {
  width: 5px;
}
app-help-center::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
}
app-help-center::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #eee;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.bg-blue {
  background-color: #00a2eb;
}

.text-blue {
  color: #00a2eb;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.5);
}

.ant-modal-body {
  padding: 0;
}

.ant-modal-content {
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
  border-radius: 10px !important;
}

.ant-modal-close {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

span.ant-modal-close-x {
  width: 26px;
  height: 26px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  color: #000000b3;
  line-height: 26px;
}

.back-store-select {
  width: 294px;
  font-size: 12px;
}
.back-store-select .ant-select-selection {
  height: 36px;
  border-radius: 0;
}
.back-store-select .ant-select-arrow:before {
  font-family: "iconfont" !important;
  content: "" !important;
  font-size: 12px;
}

.errorHit {
  position: absolute;
  line-height: 25px;
  margin-top: -25px;
  font-size: 12px;
  color: #ff5c63;
}

.handsontable {
  /*.ht_master>.wtHolder {
    &::-webkit-scrollbar {
      width: 17px;
      height: 17px;
    }
    &::-webkit-scrollbar-track {
      background: #ececec;
    }
    &::-webkit-scrollbar-thumb {
      background: #d6d6d6;
      border-radius: 8px;
      &:hover {
        background: #b6b6b6;
      }
    }
  }*/
}
.handsontable .ht_clone_left th, .handsontable .ht_clone_top_left_corner th {
  vertical-align: middle;
}
.handsontable th {
  background-clip: unset;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, "Microsoft YaHei", sans-serif, "Segoe UI Symbol";
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  line-height: normal !important;
  color: #5e5d5d !important;
  min-width: 1000px;
  height: auto;
}

button,
input {
  border: none;
  outline: none;
}

.editor-index * {
  box-sizing: initial;
}
.editor-index *::selection {
  background: #34495e;
  color: #fff;
}
.editor-index *:focus {
  outline: none;
}
.editor-index *::before, .editor-index *::after {
  box-sizing: initial;
}
.editor-index input,
.editor-index button {
  background: none;
  border: none;
  -webkit-appearance: none;
  outline: none !important;
}
.editor-index a {
  text-decoration: none;
  color: inherit;
}
.editor-index p,
.editor-index ul,
.editor-index li {
  margin: 0;
  padding: 0;
  outline: none !important;
}
.editor-index ul {
  list-style: none;
  margin: 0;
  margin-bottom: 0 !important;
}
.editor-index h1,
.editor-index h2,
.editor-index h3,
.editor-index h4,
.editor-index h5,
.editor-index h6 {
  margin-bottom: 0 !important;
  color: #5e5d5d;
}
.editor-index ol,
.editor-index ul,
.editor-index dl {
  margin-bottom: 0 !important;
}
.editor-index dd {
  margin-bottom: 0 !important;
}
.editor-index blockquote {
  margin: 0 0 0 !important;
}
.editor-index figure {
  margin: 0 0 0 !important;
}
.editor-index caption {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #5e5d5d;
}
.editor-index legend {
  margin-bottom: 0 !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
  color: #5e5d5d !important;
}
.editor-index mark {
  padding: 0 !important;
}
.editor-index .scroll-bar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.editor-index .scroll-bar::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0.2);
}
.editor-index .scroll-bar::-webkit-scrollbar-thumb:vertical {
  background-color: rgba(0, 0, 0, 0.2);
}
.editor-index .scroll-bar::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(0, 0, 0, 0.2);
}
.editor-index .flip-x {
  display: inline-block;
  transform: scaleX(-1);
}
.editor-index .flip-y {
  display: inline-block;
  transform: scaleY(-1);
}
.editor-index .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  border-radius: 5px 5px;
}
.editor-index .ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}
.editor-index .ant-modal-custom button.ant-modal-close {
  width: 26px;
  height: 26px;
  background: #fff;
  border-radius: 13px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -31px;
  top: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
.editor-index .ant-modal-custom button.ant-modal-close span.ant-modal-close-x {
  width: 26px;
  height: 26px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0px;
  color: #00a0e9;
  line-height: 26px;
}
.editor-index .ant-modal-close-x {
  display: block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 25px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.editor-index .modal-member .ant-modal-body {
  padding: 0px;
}
.editor-index div .upload-image-from-phone {
  width: 72px !important;
}
.editor-index .ant-modal {
  padding-bottom: 0;
}
.editor-index .ant-select-selection--single {
  height: 30px;
  border: 0;
  outline: none;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.editor-index .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection__placeholder,
.editor-index .ant-select-selection--single .ant-select-selection__rendered .ant-select-search__field__placeholder {
  font-size: 14px;
  color: #5e5d5d;
}
.editor-index .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
  color: #5e5d5d;
  font-size: 14px;
}
.editor-index .ant-select-arrow:before {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  transition: transform 0.3s;
}
.editor-index .ant-select-open .ant-select-selection {
  box-shadow: none;
}
.editor-index .cdk-overlay-pane .ant-select-dropdown {
  margin-top: -5px !important;
}
.editor-index .cdk-overlay-pane .ant-select-dropdown .ant-select-dropdown-menu {
  box-sizing: border-box;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 10px;
  padding-bottom: 4px;
}
.editor-index .cdk-overlay-pane .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  box-sizing: border-box;
  padding-left: 7px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #5e5d5d;
  margin-bottom: 6px;
}
.editor-index .cdk-overlay-pane .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover {
  background: #ccecfb;
  color: #00a0e9;
}
.editor-index .ant-select-selection--single:hover .ant-select-selection__clear {
  display: none;
  opacity: 0;
}
.editor-index .folder-radio {
  margin-top: 15px;
  margin-left: 20px;
}
.editor-index .folder-radio .ant-radio-wrapper {
  display: inline;
  margin-right: 14px;
}
.editor-index .folder-radio .ant-radio-inner:after {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 3px;
  top: 3px;
  border-radius: 8px;
  display: table;
  content: "";
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.editor-index .folder-radio .ant-radio-checked .ant-radio-inner:after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.editor-index .folder-radio .ant-radio-checked .ant-radio-inner {
  border: 0;
}
.editor-index .folder-radio label .ant-radio-inner {
  width: 22px;
  height: 22px;
  border-radius: 11px 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
.editor-index .folder-radio label span.ant-radio + * {
  padding: 0px;
}
.editor-index .folder-radio label:nth-of-type(1) .ant-radio-inner {
  background: #00a0e9;
}
.editor-index .folder-radio label:nth-of-type(2) .ant-radio-inner {
  background: #ea5a46;
}
.editor-index .folder-radio label:nth-of-type(3) .ant-radio-inner {
  background: #f7b600;
}
.editor-index .folder-radio label:nth-of-type(4) .ant-radio-inner {
  background: #49b56d;
}
.editor-index .folder-radio label:nth-of-type(5) .ant-radio-inner {
  background: #c174ad;
}
.editor-index .folder-radio label:nth-of-type(6) .ant-radio-inner {
  background: #8397cc;
}
.editor-index .cdk-overlay-pane .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  border-radius: 0 !important;
}
.editor-index .main {
  width: 100%;
  min-height: calc(100vh - 168px);
  background: #f6f6f6;
  padding-top: 165px !important;
  margin-top: 0 !important;
}
.editor-index .member-content {
  width: 100%;
  box-sizing: border-box;
  background: #f6f6f6;
  min-width: 1372px;
}
.editor-index input,
.editor-index button,
.editor-index select,
.editor-index optgroup,
.editor-index textarea {
  line-height: normal;
}
.editor-index table {
  border-collapse: inherit;
}
.editor-index .page {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 62px;
}
.editor-index .page ::ng-deep .ant-pagination {
  display: flex;
  align-items: center;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-prev,
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-next {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 3px 3px;
  box-shadow: 1px 2px 1px #e3e3e3;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-prev a,
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-next a {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 3px 3px;
  font-size: 18px;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-prev a:after,
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-next a:after {
  font-size: 18px;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-prev:hover a,
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-next:hover a {
  background: #00a0e9;
  color: #fff;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-item {
  width: 8px;
  height: 16px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-item a {
  font-size: 15px;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-item-active {
  width: 8px;
  height: 30px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-item-active a {
  font-size: 15px;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-options-quick-jumper {
  display: flex;
  align-items: center;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-options-quick-jumper .ant-pagination-options-quick-jumper {
  font-size: 14px;
  color: #b7b7b7;
}
.editor-index .page ::ng-deep .ant-pagination .ant-pagination-options-quick-jumper input {
  box-sizing: border-box;
  width: 47px;
  height: 32px;
  border: 0;
  border-radius: 3px 3px;
  box-shadow: 1px 2px 1px #e3e3e3;
  line-height: 32px;
  font-size: 12px;
}
.editor-index .ant-carousel .slick-slider {
  padding-bottom: 43px !important;
}
.editor-index .ant-carousel .slick-dots {
  height: 39px !important;
}
.editor-index .ant-carousel .slick-dots li {
  flex: 1;
  height: 39px;
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 4px !important;
  background: #fff;
}
.editor-index .ant-carousel .slick-dots li:last-child {
  margin-right: 0 !important;
}
.editor-index .ant-carousel .slick-dots li button {
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border-radius: 5px 5px;
  border: 2px solid #5e5d5d;
}
.editor-index .ant-carousel .slick-dots li.slick-active button {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-radius: 7px 7px;
  background: #00a0e9;
  border: 2px solid #ace0f8;
}
.editor-index .slick-dots {
  height: 14px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: 0px !important;
}
.editor-index .ant-carousel .slick-dots li {
  margin-right: 12px;
}
.editor-index ::-webkit-scrollbar {
  width: 5px;
}
.editor-index ::-webkit-scrollbar-track {
  background-color: #ededed;
}
.editor-index ::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
}
.editor-index ::-webkit-scrollbar-thumb:active {
  background-color: #dddddd;
}
.editor-index .ant-select-arrow {
  top: 48%;
  right: 5px;
  color: rgba(0, 0, 0, 0.25);
}
.editor-index .sort-content .ant-select-selection--single {
  box-shadow: 0 0 0 !important;
}
.editor-index .ant-select-dropdown-menu-item-group-title {
  padding-left: 2px;
  font-size: 14px;
  color: #bbbbbb;
}
.editor-index .use-content .ant-select-selection--single {
  box-shadow: 0 0 0 !important;
}
.editor-index .industry-content .ant-select-selection--single {
  box-shadow: 0 0 0 !important;
}
.editor-index .style-content .ant-select-selection--single {
  box-shadow: 0 0 0 !important;
}
.editor-index .color-content .ant-select-selection--single {
  box-shadow: 0 0 0 !important;
}
.editor-index .uploader .ant-upload.ant-upload-select-picture-card {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border: 0;
}
.editor-index .uploader .ant-upload.ant-upload-select-picture-card .ant-upload {
  padding: 0;
}
.editor-index .mades .ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 5px 5px;
  opacity: 1;
  margin-left: 0 !important;
}
.swiper-pagination-clickable .swiper-pagination-bullet-active {
  width: 26px;
  height: 10px;
}

::ng-deep .ant-tip {
  position: fixed !important;
  top: auto !important;
  right: auto !important;
  left: auto !important;
  bottom: auto !important;
}

::ng-deep .ant-modal-content {
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}