.front-scroll-bar{
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 5px;
    background-color: rgba(0,0,0,.3);
  }
  &::-webkit-scrollbar-track{
    border-radius: 5px;
    background-color: #eee;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
}
