@import "./fun";
@import "./reset";
@import "./tools/scollbar";
@import "./tools/component-tag";
@import "./tools/skin";
@import "./tools/modal";
@import "./tools/form";
@import "~handsontable/dist/handsontable.full.min.css";

.handsontable {
  /*.ht_master>.wtHolder {
    &::-webkit-scrollbar {
      width: 17px;
      height: 17px;
    }
    &::-webkit-scrollbar-track {
      background: #ececec;
    }
    &::-webkit-scrollbar-thumb {
      background: #d6d6d6;
      border-radius: 8px;
      &:hover {
        background: #b6b6b6;
      }
    }
  }*/

  .ht_clone_left, .ht_clone_top_left_corner {
    th {vertical-align: middle}
  }
  th {
    background-clip: unset;
  }
}

